import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'

interface ISeparator {
  side?: 'left' | 'right'
}

const Separator = styled(Divider, {
  shouldForwardProp: youShallNotPass('side'),
})<ISeparator>(({ theme, side }) => ({
  flexGrow: 1,
  borderColor: theme.tokens.ThemeColorCommonOutline,
  ...(side === 'left' && {
    marginRight: theme.spacing(2),
  }),
  ...(side === 'right' && {
    marginLeft: theme.spacing(2),
  }),
}))

export default {
  Separator,
}
